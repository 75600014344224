<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <!-- <div style="height: 25px; background: #fff"></div>
    <div class="head">
      收货管理
      <img
        class="left"
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left.png"
        alt=""
      />
    </div> -->
    <template v-if="isWxApplets">
      <van-nav-bar
        title="地址管理"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 内容 -->
    <div class="outside">
      <van-radio-group v-model="radio" @change="changeDefault">
        <div
          class="content"
          v-for="(item, i) in addressLsit"
          :key="i"
          @click="selected(item)"
        >
          <div class="content_one">
            <div class="name_phone">
              <span>{{ item.ContactName }}</span>
              <span style="margin-left: 20px">{{ item.ContactPhone }}</span>
            </div>
            <div class="address">
              {{ item.Province + item.City + item.Area + item.Address }}
            </div>
          </div>

          <div class="content_two">
            <div class="content_ok" @click.stop="alert()">
              <van-radio :name="i" checked-color="#000">设为默认</van-radio>
            </div>
            <div class="content_set">
              <div class="content_set_one">
                <img src="../../../assets/ShopImg/bj.png" alt="" />
                <div @click.stop="goedit(item)">编辑</div>
              </div>
              <div class="content_set_one ml">
                <img src="../../../assets/ShopImg/del.png" alt="" />
                <div @click.stop="deleteaddress(item, i)">删除</div>
              </div>
              <!-- <div class="content_set_one" style="margin-left: 15px">
                <img src="../../../assets/ShopImg/del.png" alt="" />
                <div>删除</div>
              </div> -->
            </div>
          </div>
        </div>
      </van-radio-group>
      <div v-if="addressLsit.length <= 0">
        <nocontent name="暂无收货地址"></nocontent>
      </div>
    </div>

    <div class="address-footer">
      <van-button
        block
        class="btns2"
        :to="'/shop/address/detail?goodsData=' + goodsData"
        color="linear-gradient(99deg, #000 0%, #000 100%)"
        round
        >添加收货地址
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  queryUserAddress,
  editUserAddress,
  deleteUserAddress,
} from "@/api/shop";
import { Toast, Dialog } from "vant";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      userInfo: {},
      pageIndex: 1,
      pageSize: 10,
      addressLsit: [],
      radio: "0",
      isEnd: false,
      goodsData: "",
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "地址管理";
      this.isWxApplets = false;
    }
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.queryUserAddress();
    } else {
      this.userInfo.Id = 0;
    }
    if (this.$route.query.goodsData) {
      this.goodsData = this.$route.query.goodsData;
    }
    console.log(this.goodsData, "goodsData");
    // this.listenScroll(document.documentElement);
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    alert() {},
    goedit(item) {
      console.log(item);
      this.$router.push({
        path: "/shop/address/detail",
        query: { addressData: JSON.stringify(item), goodsData: this.goodsData },
      });
    },
    selected(item) {
      if (this.goodsData) {
        this.$router.push({
          path: "/shop/orders/confirmOrder",
          query: {
            addressData: JSON.stringify(item),
            goodsData: this.goodsData,
          },
        });
      }
    },
    changeDefault(name) {
      // console.log(name);
      let params = this.addressLsit[name];
      params.IsDefault = 1;
      const res = editUserAddress(params);
    },
    async queryUserAddress() {
      let params = {};
      params.pageIndex = this.pageIndex;
      params.pageSize = this.pageSize;
      params.userId = this.userInfo.Id;
      const res = await queryUserAddress(params);
      this.addressLsit = this.addressLsit.concat(res.response.data);
      for (let index = 0; index < this.addressLsit.length; index++) {
        if (this.addressLsit[index].IsDefault == 1) {
          this.radio = index;
        }
      }
    },
    // 删除地址
    deleteaddress(content, index) {
      console.log(index);
      Dialog.confirm({
        title: "",
        message: "是否删除该地址？",
      })
        .then(async () => {
          const res = await deleteUserAddress(content.Id);
          if (res.success == true) {
            const toast = Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: "删除成功",
            });
            let second = 1;
            const timer = setInterval(() => {
              second--;
              if (second) {
                console.log("qqq");
              } else {
                clearInterval(timer);
                // 手动清除 Toast
                Toast.clear();
              }
            }, 2000);
            this.addressLsit.splice(index, 1);
          } else {
            Toast.fail("删除失败，请重试");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;

  .left {
    width: 22px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.outside {
  width: 92%;
  margin-left: 4%;
  padding-bottom: 15px;
}

.content {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  margin-bottom: 15px;

  .content_one {
    padding: 15px;
    border-bottom: 1px solid #f5f5f5;

    .name_phone {
      font-size: 14px;
      color: #333;
      font-weight: bold;
    }

    .address {
      margin-top: 15px;
      color: #666666;
      font-size: 14px;
    }
  }

  .content_two {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    color: #969696;
    font-size: 12px;

    .content_ok {
      display: flex;
      align-items: center;

      .yuan {
        width: 12px;
        height: 12px;
        border: 1px solid #dfdfdf;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .content_set {
      display: flex;
      align-items: center;

      .content_set_one {
        display: flex;
        align-items: center;
      }

      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}

.btns2 {
  width: 80%;
  margin: 0 auto;
  z-index: 100;
  // position: flex;
  bottom: 10px;
}
.ml {
  margin-left: 15px;
}
.van-button {
  background: #000;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
